@import "@/assets/scss/style.scss";

.co-login {
    // display: flex;
    background-color: $very-light-blue-two;
    &__main {
        display: flex;
        flex-wrap: nowrap;
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
        background: #386ec2;
        overflow: hidden;
    }
    &__logo {
        margin-top: 40px;
        margin-left: 40px;
    }

    &__header {
        background: #e8eff9;
    }

    &__footer {
        position: fixed;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-left: 40px;
        margin-bottom: 40px;
        color: #8c8c8c;
        left: 0;
        bottom: 0;
    }

    &__message {
        margin-bottom: 16px;
        color: $black;
    }

    &__additional-info {
        margin-bottom: 24px;
        text-align: center;
        max-width: 380px;
    }

    @media (orientation: portrait) {
        &__right {
            display: none;
        }
        &__left {
            width: 100%;
        }
    }

    &__additional-info {
        margin-bottom: 24px;
        text-align: center;
        max-width: 380px;
    }
}

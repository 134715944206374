@import "@/assets/scss/style.scss";

.co-login {
    &__form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }

    &__form-fields {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-left: 8%;
        border-radius: 18px;
        margin-bottom: 50px;
    }

    &__form-row {
        width: 100%;
        margin-bottom: 22px;
    }

    &__button {
        width: 100%;
        border-radius: 2px;
        align-items: center;
        height: 40px;
    }

    &__spinner-wrapper {
        text-align: center;
        height: 30px;
    }

    &__forgot-password {
        font-family: 'Roboto-Regular', 'Arial', sans-serif;
        color: #1890ff;
        float: right;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-right: 80px;
    }

    &__form-text {
        font-family: 'Roboto-Regular', 'Arial', sans-serif;
        width: 64px;
        height: 20px;
        /* Regular/14px | 20px */
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-style: normal;
        /* identical to box height, or 143% */
        text-align: right;
        /* Grey / grey-8 */
        color: #7e7d7d;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    &__button,
    &__form-input {
        width: 40vh;
    }
    &__form-title {
        font-family: 'Roboto-Regular', 'Arial', sans-serif;
        font-style: normal;
        font-weight: 350;
        font-size: 34px;
        line-height: 40px;
        /* identical to box height, or 118% */
        margin-bottom: 30px;
        /* Grey / grey-9 */
        color: #262626;
    }
}
